import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"

library.add(fas)

export default function Blog() {
  const { t } = useTranslation()
  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    query BlogPageQuery {
      results: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog" } } }
        sort: { order: DESC, fields: frontmatter___datetime }
      ) {
        edges {
          node {
            frontmatter {
              title
              title_en
              content
              content_en
              datetime
              templateKey
              headerImage
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  const posts = data.results.edges
    .filter(edge => !edge.node.frontmatter.title.startsWith("dummy__"))
    .map(edge => {
      const post = edge.node

      const title =
        language === "en" ? post.frontmatter.title_en : post.frontmatter.title
      const content =
        language === "en"
          ? post.frontmatter.content_en
          : post.frontmatter.content
      let summary = content?.slice(0, 420)
      summary = summary?.replace(
        /(\*\*)|(\\\s)|(__)|!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)|(?:__|[*#])|\[(.*?)\]\(.*?\)/gim,
        " "
      ) // strip markdown symbols (italics, bolds, links and images)

      if (language === "en") {
        dayjs.locale("en")
      } else {
        dayjs.locale("tr")
      }
      return (
        <div className="blog" key={post.frontmatter.title}>
          <img
            className="blog-img"
            src={post.frontmatter.headerImage || "/assets/img/blog/1.png"}
            alt="img"
          />
          <div className="blog-title">{title}</div>
          <div className="blog-preview">{summary}</div>
          <div className="publish-or-go">
            <div className="publish-date">
              {dayjs(post.frontmatter.datetime).format("D MMMM, YYYY")}
            </div>
            <Link to={post.fields.slug}>
              {t("devamini-oku")}
              <FontAwesomeIcon icon={["fas", "chevron-right"]} size="1x" />
            </Link>
          </div>
        </div>
      )
    })

  return (
    <Layout>
      <SEO title={t("blog")} />
      <div className="blog-container">{posts}</div>
    </Layout>
  )
}
